<template>
  <div class="text-center">
    <div v-if="!loading">
      <v-sheet
        :elevation="1"
        :color="color"
        class="d-flex justify-center pa-3"
        @click="hoverShow = false; $emit('clickViewDetails')"
        v-ripple
        v-if="eventSummary && eventSummary.count > 0" 
      >
        <v-menu v-model="hoverShow" open-on-hover auto offset-y offset-x close-on-click>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <span v-if="display == 'index'">
                {{ eventSummary.index }}
              </span>
              <span v-if="display == 'value' || display == 'value-quantity'">
                {{ eventSummary.totalValue | currency }}
              </span>
              <span v-if="display == 'quantity' || display == 'value-quantity'">
                {{ eventSummary.totalQuantity | formatNumber }}
                <v-icon
                  v-if="eventSummary.hasAdjustment"
                  small
                  color="red darken-2"
                >
                  mdi-alert
                </v-icon>
              </span>
            </div>
          </template>
          <v-list>
            <v-alert v-if="eventSummary.hasAdjustment" type="warning">
              Contains adjustments
            </v-alert>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td>Date</td>
                  <td>{{ dateRange.startTime | formatDate }}</td>
                </tr>
                <tr>
                  <td>Product</td>
                  <td>{{ product.name }}</td>
                </tr>
                <tr>
                  <td>Count</td>
                  <td>{{ eventSummary.count }} events</td>
                </tr>
                <tr>
                  <td>Net Value</td>
                  <td>{{ eventSummary.totalValue | currency }}</td>
                </tr>
                <tr>
                  <td>Net Quantity</td>
                  <td>{{ eventSummary.totalQuantity | formatNumber }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-list>
        </v-menu>
      </v-sheet>
    </div>
    <v-progress-circular
      :width="3"
      color="primary"
      indeterminate
      v-else
    ></v-progress-circular>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InventoryEventGroup from "@/services/InventoryEventGroup.js";

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    dateRange: {
      type: Object,
      required: true,
    },
    display: {
      type: String,
      default: "quantity",
    },
  },
  data: () => ({
    eventSummary: null,
    color: "grey lighten-3",
    timeStamp: Date.now(),
    loading: true,
    hoverShow: false,
  }),
  computed: {
    ...mapGetters("inventoryEvents", ["eventsService", "eventsDateRange"]),
    ...mapGetters("inventoryCostAdj", ["costAdjustmentService"]),
  },
  async mounted() {
    if(this.product.id == 0){
      console.log('mounted all product')
    }

    const group = new InventoryEventGroup(
          this.product,
          this.dateRange.startTime,
          this.dateRange.endTime
        );
    setTimeout(() => {
        const groupedEvents = this.eventsService ? this.eventsService.getGroupedEvents(group, this.costAdjustmentService) : null ;
        if(this.product.id == 0){
          console.log('group all products: ', this.groupedEvents)
        }
        
        this.eventSummary = {
          totalValue: groupedEvents.totalValue,
          totalQuantity: groupedEvents.totalQuantity,
          count: groupedEvents.size,
          hasAdjustment: groupedEvents.hasAdjustment,
        }
        this.color = this.getEventColor();

        // console.log("Loaded data for ", group);
        this.loading = false;
      }, 1);

  },
  methods: {
    getEventColor() {
      if (this.display == "index") {
        const index = this.eventSummary ? this.eventSummary.index : 0;
        if (index == null) {
          return "";
        } else if (index > 0) {
          return "green lighten-3";
        } else if (index < 0) {
          return "orange lighten-3";
        } else if (index == 0) {
          return "grey lighten-3";
        }
      } else {
        const quantity = this.eventSummary
          ? this.eventSummary.totalQuantity
          : 0;
        if (quantity == null) {
          return "";
        } else if (quantity > 0) {
          return "green lighten-3";
        } else if (quantity < 0) {
          return "orange lighten-3";
        } else if (quantity == 0) {
          return "grey lighten-3";
        }
      }
    },
    groupByTypes() {
      let type2Events = {};
      if (
        this.eventSummary &&
        this.eventSummary.details &&
        this.eventSummary.details.length > 0
      ) {
        type2Events = this.eventSummary.details.reduce((group, inventory) => {
          const type = event.type;
          if (!group[type]) {
            group[type] = {
              quantity: 0,
              value: 0,
            };
          }

          group[type].quantity += inventory.quantityRemaining;
          group[type].value += inventory.remainingValue;

          return group;
        }, {});
      }

      return type2Events;
    },
  },
};
</script>

<style scoped>
div {
  cursor: pointer;
}
</style>
