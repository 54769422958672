const parseISOLocal = (s) => {
    var b = s.split(/\D/);
    return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
  }
export default class InventoryEventGroup{

    constructor(product, startTime, endTime, eventTypes) {
        this.product = product;
        this.startTime = startTime;
        this.endTime = endTime;
        this.eventTypes = eventTypes;
    }

}

InventoryEventGroup.prototype.toString = function (){
    const pKeys = this.product ? this.product.id:'';
    const eKeys = this.eventTypes && this.eventTypes.length > 0 ? this.eventTypes.join('-'):'';
    const startTime = this.startTime instanceof Date ? this.startTime:parseISOLocal(this.startTime);
    const endTime = this.endTime instanceof Date ? this.endTime:parseISOLocal(this.endTime);
    return pKeys + '-' + startTime.getTime() + '-' + endTime.getTime() + eKeys;
}